<template>
  <div
    class="gps-page3"
    style="background: #fff; padding: 10px; min-height: 100%; height: auto"
  >
    <div
      class="gps-body"
      style="min-height: calc(100% - 20px); border-radius: 10px"
    >
      <gps-tabs-form
        :formArray="formArray"
        :tabFirstName="tabFirstName"
        :row="formRow"
        @saveEvent="saveEvent"
      ></gps-tabs-form>
    </div>
  </div>
</template>
<script setup>
import { reactive, ref, getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
import GpsTabsForm from "./components/GpsTabsForm.vue";
import { ElMessage } from "element-plus";
import refresh_table from "../../utils/refrshTable";
const { t } = useI18n();
let { proxy } = getCurrentInstance();

//声明变量
const formRow = ref({});

const formFieldsArray = [
  {
    label: t("fwqpz.sbwg"),
    name: "sbwg",
    children: [
      {
        type: "ipt",
        label: t("fwqpz.jtbbsbdk"),
        field: "RevPort",
        placeholder: t("fwqpz.jtbbsbdk"),
        val: "",
        required: false,
      },
      {
        type: "ipt",
        label: t("fwqpz.yhfwip"),
        field: "CenterIP",
        placeholder: t("fwqpz.yhfwip"),
        val: "",
        required: false,
      },
      {
        type: "radio",
        label: t("fwqpz.ygxzzdzc"),
        field: "DeviceRegister",
        placeholder: t("fwqpz.ygxzzdzc"),
        val: "1",
        required: false,
        data: [
          {
            value: "true",
            name: t("fwqpz.kq"),
          },
          {
            value: "false",
            name: t("fwqpz.gb"),
          },
        ],
      },
    ],
  },
  {
    label: t("fwqpz.yhfw"),
    name: "yhfw",
    children: [
      {
        type: "ipt",
        label: t("fwqpz.jtwebqqdk"),
        field: "WebSocketPort",
        placeholder: t("fwqpz.jtwebqqdk"),
        val: "",
        required: true,
      },
      {
        type: "ipt",
        label: t("fwqpz.webzsmm"),
        field: "WebSocketSSLPwd",
        placeholder: t("fwqpz.webzsmm"),
        val: "",
        required: true,
      },
      {
        type: "ipt",
        label: t("fwqpz.webzslj"),
        field: "WebSocketSSLPath",
        placeholder: t("fwqpz.webzslj"),
        val: "",
        required: true,
      },
      {
        type: "radio",
        label: t("fwqpz.webqyssl"),
        field: "WebSocketSSL",
        placeholder: t("fwqpz.webqyssl"),
        val: "true",
        required: false,
        data: [
          {
            value: "true",
            name: t("fwqpz.kq"),
          },
          {
            value: "false",
            name: t("fwqpz.gb"),
          },
        ],
      },
      {
        type: "ipt",
        label: t("fwqpz.jtkhddk"),
        field: "ClientPort",
        placeholder: t("fwqpz.jtkhddk"),
        val: "",
        required: true,
      },
      {
        type: "ipt",
        label: t("fwqpz.jtsbwgfwdk"),
        field: "ServicePort",
        placeholder: t("fwqpz.jtsbwgfwdk"),
        val: "",
        required: true,
      },
      {
        type: "ipt",
        label: t("fwqpz.jthttpqqdk"),
        field: "HttpPort",
        placeholder: t("fwqpz.jthttpqqdk"),
        val: "",
        required: true,
      },
      {
        type: "ipt",
        label: t("fwqpz.fjfwqjtdk"),
        field: "AttachmentPort",
        placeholder: t("fwqpz.fjfwqjtdk"),
        val: "",
        required: true,
      },
      {
        type: "ipt",
        label: t("fwqpz.zcxx"),
        field: "RegisterString",
        placeholder: t("fwqpz.zcxx"),
        val: "",
        required: false,
      },
      {
        type: "ipt",
        label: t("fwqpz.dtwzhqdz"),
        field: "GetMapAddressUrl",
        placeholder: t("fwqpz.dtwzhqdz"),
        val: "",
        required: true,
      },
      {
        type: "ipt",
        label: t("fwqpz.spfwqdz"),
        field: "VideoServerIp",
        placeholder: t("fwqpz.spfwqdz"),
        val: "",
        required: true,
      },
      {
        type: "ipt",
        label: t("fwqpz.spfwqyldk"),
        field: "VideoServerPort",
        placeholder: t("fwqpz.spfwqyldk"),
        val: "",
        required: true,
      },
      {
        type: "ipt",
        label: t("fwqpz.sphfdk"),
        field: "VideoServerPlaybackPort",
        placeholder: t("fwqpz.sphfdk"),
        val: "",
        required: true,
      },
      {
        type: "ipt",
        label: t("fwqpz.spkhddk"),
        field: "VideoClientPort",
        placeholder: t("fwqpz.spkhddk"),
        val: "",
        required: true,
      },
      {
        type: "ipt",
        label: t("fwqpz.spflvdk"),
        field: "VideoFLVPort",
        placeholder: t("fwqpz.spflvdk"),
        val: "",
        required: true,
      },
      {
        type: "ipt",
        label: t("fwqpz.splzthqdz"),
        field: "VideoStateStateUrl",
        placeholder: t("fwqpz.splzthqdz"),
        val: "",
        required: true,
      },
      {
        type: "radio",
        label: t("fwqpz.spqyhttps"),
        field: "VideoSSLEnable",
        placeholder: t("fwqpz.spqyhttps"),
        val: "true",
        required: false,
        data: [
          {
            value: "true",
            name: t("fwqpz.kq"),
          },
          {
            value: "false",
            name: t("fwqpz.gb"),
          },
        ],
      },
    ],
  },
  {
    label: t("fwqpz.tycs"),
    name: "tycs",
    children: [
      {
        type: "ipt",
        label: t("fwqpz.bdip"),
        field: "LocalIP",
        placeholder: t("fwqpz.bdip"),
        val: "",
        required: true,
      },
      {
        type: "ipt",
        label: t("fwqpz.glsbidh"),
        field: "FilterID",
        placeholder: t("fwqpz.glsbidh"),
        val: "",
        required: true,
      },
      {
        type: "ipt",
        label: t("fwqpz.fwqgwip"),
        field: "AddressIP",
        placeholder: t("fwqpz.fwqgwip"),
        val: "",
        required: true,
      },
      {
        type: "radio",
        label: t("fwqpz.qyqjts"),
        field: "ShowDebug",
        placeholder: t("fwqpz.qyqjts"),
        val: "true",
        required: false,
        data: [
          {
            value: "true",
            name: t("fwqpz.kq"),
          },
          {
            value: "false",
            name: t("fwqpz.gb"),
          },
        ],
      },
    ],
  },
  {
    label: t("fwqpz.cccs"),
    name: "cccs",
    children: [
      {
        type: "ipt",
        label: t("fwqpz.ftpipdz"),
        field: "FTPIp",
        placeholder: t("fwqpz.ftpipdz"),
        val: "",
        required: true,
      },
      {
        type: "ipt",
        label: t("fwqpz.ftpdk"),
        field: "FTPPort",
        placeholder: t("fwqpz.ftpdk"),
        val: "",
        required: true,
      },
      {
        type: "ipt",
        label: t("fwqpz.ftpyhmc"),
        field: "FTPUserName",
        placeholder: t("fwqpz.ftpyhmc"),
        val: "",
        required: true,
      },
      {
        type: "ipt",
        label: t("fwqpz.ftpyhmm"),
        field: "FTPUserPwd",
        placeholder: t("fwqpz.ftpyhmm"),
        val: "",
        required: true,
      },
      {
        type: "ipt",
        label: t("fwqpz.bjyjjsdz"),
        field: "AlarmToEmail",
        placeholder: t("fwqpz.bjyjjsdz"),
        val: "",
        required: true,
      },
      {
        type: "radio",
        label: t("fwqpz.yjnryy"),
        field: "EmailLanguage",
        placeholder: t("fwqpz.yjnryy"),
        val: "1",
        required: false,
        data: [
          {
            value: "zh-cn",
            name: t("fwqpz.chinese"),
          },
          {
            value: "en",
            name: t("fwqpz.en"),
          },
        ],
      },
      {
        type: "radio",
        label: t("fwqpz.fwqnryy"),
        field: "ServerLanguage",
        placeholder: t("fwqpz.fwqnryy"),
        val: "1",
        required: false,
        data: [
          {
            value: "zh-cn",
            name: t("fwqpz.chinese"),
          },
          {
            value: "en",
            name: t("fwqpz.en"),
          },
        ],
      },
      // {
      //   type: "ipt",
      //   label: t("fwqpz.EmailLanguage"),
      //   field: "EmailLanguage",
      //   placeholder: t("fwqpz.EmailLanguage"),
      //   val: "",
      //   required: true,
      // },
      // {
      //   type: "ipt",
      //   label: t("fwqpz.EmailLanguage"),
      //   field: "EmailLanguage",
      //   placeholder: t("fwqpz.EmailLanguage"),
      //   val: "",
      //   required: true,
      // },
      // {
      //   type: "ipt",
      //   label: t("fwqpz.ServerLanguage"),
      //   field: "ServerLanguage",
      //   placeholder: t("fwqpz.ServerLanguage"),
      //   val: "",
      //   required: true,
      // },
      {
        type: "ipt",
        label: t("fwqpz.bjyjnrmbhtml"),
        field: "EmailContent",
        placeholder: t("fwqpz.bjyjnrmbhtml"),
        val: "",
        required: false,
      },
      {
        type: "ipt",
        label: t("fwqpz.ccwz"),
        field: "AVIPath",
        placeholder: t("fwqpz.ccwz"),
        val: "",
        required: true,
      },
      {
        type: "ipt",
        label: t("fwqpz.xdwz"),
        field: "AVIUrl",
        placeholder: t("fwqpz.xdwz"),
        val: "",
        required: true,
      },
      {
        type: "ipt",
        label: t("fwqpz.zdcckj"),
        field: "MinStoreSpace",
        placeholder: t("fwqpz.zdcckj"),
        val: "",
        required: true,
      },
      {
        type: "ipt",
        label: t("fwqpz.yjcckj"),
        field: "AlarmStoreSpace",
        placeholder: t("fwqpz.yjcckj"),
        val: "",
        required: true,
      },
      {
        type: "ipt",
        label: t("fwqpz.lxblts"),
        field: "VideoRetentionDays",
        placeholder: t("fwqpz.lxblts"),
        val: "",
        required: true,
      },
      {
        type: "ipt",
        label: t("fwqpz.gjblts"),
        field: "TrackRetentionDays",
        placeholder: t("fwqpz.gjblts"),
        val: "",
        required: true,
      },
    ],
  },
  {
    label: t("fwqpz.logo_title"),
    name: "logo_title",
    children: [
      // {
      //   type: "ipt",
      //   label: t("fwqpz.website_name"),
      //   field: "website_name",
      //   placeholder: t("fwqpz.website_name"),
      //   val: "",
      //   required: true,
      // },
      {
        type: "upload",
        label: t("fwqpz.logo_photo"),
        field: "logo",
        placeholder: t("fwqpz.logo_photo"),
        val: "",
        required: true,
      },
      {
        type: "upload",
        label: t("fwqpz.login_pz"),
        field: "login_url",
        placeholder: t("fwqpz.login_pz"),
        val: "",
        required: true,
      },
    ],
  },
  {
    label: t("fwqpz.smtppz"),
    name: "smtppz",
    children: [
      {
        type: "smpt_ipt",
        label: t("fwqpz.form"),
        field: "from",
        placeholder: t("fwqpz.form"),
        val: "",
        required: false,
      },
      {
        type: "smpt_ipt",
        label: t("fwqpz.user"),
        field: "username",
        placeholder: t("fwqpz.user"),
        val: "",
        required: false,
      },
      {
        type: "smpt_ipt",
        label: t("fwqpz.pwd"),
        field: "pwd",
        placeholder: t("fwqpz.pwd"),
        val: "",
        required: false,
      },
      {
        type: "smpt_ipt",
        label: t("fwqpz.dz"),
        field: "SMTPIP",
        placeholder: t("fwqpz.dz"),
        val: "",
        required: false,
      },
      {
        type: "smpt_ipt",
        label: t("fwqpz.dk"),
        field: "SMTPPort",
        placeholder: t("fwqpz.dk"),
        val: "",
        required: false,
      },
      {
        type: "smpt_ipt",
        label: t("fwqpz.zt"),
        field: "theme",
        placeholder: t("fwqpz.zt"),
        val: "",
        required: false,
      },
      {
        type: "smpt_radio",
        label: t("fwqpz.jmlx"),
        field: "encryptionType",
        placeholder: t("fwqpz.jmlx"),
        val: "0",
        required: true,
        data: [
          {
            value: "0",
            name: t("fwqpz.bjm"),
          },
          {
            value: "1",
            name: "SSL",
          },
        ],
      },
    ],
  },
  {
    label: t("fwqpz.webpz"),
    name: "webpz",
    children: [
      {
        type: "ipt",
        label: t("fwqpz.websocketdz"),
        field: "SOCKET_IP",
        placeholder: t("fwqpz.websocketdz"),
        val: "",
        required: false,
      },
      {
        type: "ipt",
        label: t("fwqpz.websocketdk"),
        field: "SOCKET_PORT",
        placeholder: t("fwqpz.websocketdk"),
        val: "",
        required: false,
      },
      {
        type: "ipt",
        label: t("fwqpz.spfwqdz"),
        field: "Video_Server_Ip",
        placeholder: t("fwqpz.spfwqdz"),
        val: "",
        required: false,
      },
      {
        type: "ipt",
        label: t("fwqpz.spfwqyldk"),
        field: "Video_Server_Port",
        placeholder: t("fwqpz.spfwqyldk"),
        val: "",
        required: false,
      },
      {
        type: "ipt",
        label: t("fwqpz.sphfdk"),
        field: "Video_Server_Port_Playback",
        placeholder: t("fwqpz.sphfdk"),
        val: "",
        required: false,
      },
      {
        type: "ipt",
        label: t("fwqpz.spkhddk"),
        field: "Video_Client_Port",
        placeholder: t("fwqpz.spkhddk"),
        val: "",
        required: false,
      },
      {
        type: "ipt",
        label: t("fwqpz.spflvdk"),
        field: "Video_FLV_Port",
        placeholder: t("fwqpz.spflvdk"),
        val: "",
        required: false,
      },
      {
        type: "ipt",
        label: t("fwqpz.djdk"),
        field: "Intercom_Port",
        placeholder: t("fwqpz.djdk"),
        val: "",
        required: false,
      },
      {
        type: "radio",
        label: t("fwqpz.mrdt"),
        field: "Default_map",
        placeholder: t("fwqpz.mrdt"),
        val: "",
        required: true,
        data: [
          {
            value: "1",
            name: t("fwqpz.ge"),
          },
          {
            value: "2",
            name: t("fwqpz.bd"),
          },
          {
            value: "3",
            name: "Open Street Map",
          },
        ],
      },
      // {
      //   type: "ipt",
      //   label: t("fwqpz.b"),
      //   field: "Default_map",
      //   placeholder: t("fwqpz.b"),
      //   val: "",
      //   required: true,
      // },
      // {
      //   type: "ipt",
      //   label: t("fwqpz.os"),
      //   field: "Default_map",
      //   placeholder: t("fwqpz.os"),
      //   val: "",
      //   required: true,
      // },
      {
        type: "pwd",
        label: t("fwqpz.gekey"),
        field: "googleKey",
        placeholder: t("fwqpz.gekey"),
        val: "",
        required: false,
      },
      {
        type: "pwd",
        label: t("fwqpz.bdkey"),
        field: "baiduKey",
        placeholder: t("fwqpz.bdkey"),
        val: "",
        required: false,
      },
      {
        type: "ipt",
        label: t("fwqpz.geapidz"),
        field: "google_api_url",
        placeholder: t("fwqpz.geapidz"),
        val: "",
        required: false,
      },
      // {
      //   type: "ipt",
      //   label: t("fwqpz.maxCar"),
      //   field: "BaiduMaxCarCounts",
      //   placeholder: t("fwqpz.maxCar"),
      //   val: "",
      //   required: false,
      // },
      // {
      //   type: "radio",
      //   label: t("fwqpz.zdhqwz"),
      //   field: "autoGetAddress",
      //   placeholder: t("fwqpz.zdhqwz"),
      //   val: "",
      //   required: false,
      //   data: [
      //     {
      //       value: "1",
      //       name: t("fwqpz.kq"),
      //     },
      //     {
      //       value: "2",
      //       name: t("fwqpz.gb"),
      //     },
      //   ],
      // },
      // {
      //   type: "radio",
      //   label: t("fwqpz.sytx"),
      //   field: "autoGetAudio",
      //   placeholder: t("fwqpz.sytx"),
      //   val: "",
      //   required: false,
      //   data: [
      //     {
      //       value: "1",
      //       name: t("fwqpz.kq"),
      //     },
      //     {
      //       value: "2",
      //       name: t("fwqpz.gb"),
      //     },
      //   ],
      // },
      // {
      //   type: "ipt",
      //   label: t("fwqpz.autoGetAudio"),
      //   field: "autoGetAudio",
      //   placeholder: t("fwqpz.autoGetAudio"),
      //   val: "",
      //   required: false,
      // },
      {
        type: "ipt",
        label: t("fwqpz.bdkey_array"),
        field: "BaiduKey_array",
        placeholder: t("fwqpz.bdkey_array"),
        val: "",
        required: false,
      },
      {
        type: "radio",
        label: t("fwqpz.mryy"),
        field: "gps_lang",
        placeholder: t("fwqpz.mryy"),
        val: "",
        required: true,
        data: [
          {
            value: "zh-cn",
            name: t("fwqpz.chinese"),
          },
          {
            value: "en",
            name: t("fwqpz.en"),
          },
          {
            value: "vi",
            name: t("fwqpz.vi"),
          },
        ],
      },
      // {
      //   type: "ipt",
      //   label: t("fwqpz.e"),
      //   field: "gps_lang",
      //   placeholder: t("fwqpz.e"),
      //   val: "",
      //   required: false,
      // },
      // {
      //   type: "ipt",
      //   label: t("fwqpz.v"),
      //   field: "gps_lang",
      //   placeholder: t("fwqpz.v"),
      //   val: "",
      //   required: false,
      // },
      {
        type: "radio",
        label: t("fwqpz.sddw"),
        field: "SpeedUnit",
        placeholder: t("fwqpz.sddw"),
        val: "",
        required: true,
        data: [
          {
            value: "1",
            name: "KM/H",
          },
          {
            value: "2",
            name: "MPH",
          },
        ],
      },
      // {
      //   type: "ipt",
      //   label: t("fwqpz.SpeedUnit"),
      //   field: "SpeedUnit",
      //   placeholder: t("fwqpz.SpeedUnit"),
      //   val: "",
      //   required: true,
      // },
      {
        type: "ipt",
        label: t("fwqpz.gbsj"),
        field: "videoTime",
        placeholder: t("fwqpz.gbsj"),
        val: "",
        required: true,
      },
    ],
  },
  // {
  //   label: t("fwqpz.apppz"),
  //   name: "apppz",
  //   children: [
  //     {
  //       type: "ipt",
  //       label: t("fwqpz.azbb"),
  //       field: "androidvar",
  //       placeholder: t("fwqpz.azbb"),
  //       val: "",
  //       required: true,
  //     },
  //     {
  //       type: "ipt",
  //       label: t("fwqpz.azsjurl"),
  //       field: "androidapkurl",
  //       placeholder: t("fwqpz.azsjurl"),
  //       val: "",
  //       required: true,
  //     },
  //     {
  //       type: "ipt",
  //       label: t("fwqpz.androidremark"),
  //       field: "androidremark",
  //       placeholder: t("fwqpz.androidremark"),
  //       val: "",
  //       required: true,
  //     },
  //     {
  //       type: "ipt",
  //       label: t("fwqpz.iosbb"),
  //       field: "iosvar",
  //       placeholder: t("fwqpz.iosbb"),
  //       val: "",
  //       required: true,
  //     },
  //     {
  //       type: "ipt",
  //       label: t("fwqpz.iossjurl"),
  //       field: "iosurl",
  //       placeholder: t("fwqpz.iossjurl"),
  //       val: "",
  //       required: true,
  //     },
  //     {
  //       type: "ipt",
  //       label: t("fwqpz.iosremark"),
  //       field: "iosremark",
  //       placeholder: t("fwqpz.iosremark"),
  //       val: "",
  //       required: true,
  //     },
  //   ],
  // },
];

const formArray = reactive(formFieldsArray);
const tabFirstName = ref("sbwg");

//初始化数据
proxy.$api.sys_get_fwqpz({}).then((res) => {
  let row = res.data.row;
  if (!row.EmailSMTP) {
    row.EmailSMTP = {};
  }

  if (row.EmailSMTP && typeof row.EmailSMTP === "string") {
    row.EmailSMTP = JSON.parse(row.EmailSMTP);
  }
  // console.log(row);

  formRow.value = row;
});
////////////////////////////////

const saveEvent = (temp) => {
  // console.log(temp);
  temp.EmailSMTP = JSON.stringify(temp.EmailSMTP);
  proxy.$api.sys_save_fwqpz(temp).then((res) => {
    ElMessage({
      message: t("commKey.MSG_" + res.data.msg_type),
      type: "success",
    });
    refresh_table("gps_serverconfigs", 5);
  });
};
</script>
<style>
.demo-tabs > .el-tabs__content {
  padding: 32px;
  color: #6b778c;
  font-size: 32px;
  font-weight: 600;
}
.gps-page3 {
  background: #fff !important;
  padding: 10px;
  height: 100%;
  border: 1px solid #ccc;
}
::v-deep .gps-body {
  background: #fff !important;
}
</style>
